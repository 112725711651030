import { ActionTypeList } from '../../constants'

const initialSubmissionState = {
    loading: false,
    isTargetAuthenticationTestSubmitted: false,
    error: "",
};

export const targetAuthenticationTestSubmissionPayload = (state = initialSubmissionState, action) => {
    //console.log(`targetAuthenticationTestSubmissionPayload initial state ${JSON.stringify(state)} action type ${action.type} payload ${JSON.stringify(action.payload)}`);

    let response = {};

    switch (action.type) {
        case ActionTypeList.TARGET_SETTING_AUTHENTICATION_TEST_SUBMITTED:
            response = {
                loading: false,
                isTargetAuthenticationTestSubmitted: true,
                error: ""
            };
            break;
        case ActionTypeList.TARGET_SETTING_SUBMIT_AUTHENTICATION_TEST:
            response = {
                loading: true,
                error: action.payload.data.error
            };
            break;
        case ActionTypeList.TARGET_SETTING_AUTHENTICATION_TEST_RESULT_RESET:
            response = {
                loading: false,
                isTargetAuthenticationTestSubmitted: false,
                error: ""
            };
            break;
        default:
            response = state;
            break;
    }

    //console.log(`final targetAuthenticationTestSubmissionPayload from action ${action.type}: ${JSON.stringify(response)}`)

    return response;
}


const initialCancellationSubmissionState = {
    loading: false,
    isTargetAuthenticationTestCancellationSubmitted: false,
    error: "",
};

export const targetAuthenticationTestCancellationSubmissionPayload = (state = initialCancellationSubmissionState, action) => {
    //console.log(`targetAuthenticationTestCancellationSubmissionPayload initial state ${JSON.stringify(state)} action type ${action.type} payload ${JSON.stringify(action.payload)}`);

    let response = {};

    switch (action.type) {
        case ActionTypeList.TARGET_SETTING_AUTHENTICATION_TEST_CANCELLATION_SUBMITTED:
            response = {
                loading: false,
                isTargetAuthenticationTestCancellationSubmitted: true,
                error: ""
            };
            break;
        case ActionTypeList.TARGET_SETTING_SUBMIT_AUTHENTICATION_TEST_CANCELLATION:
            response = {
                loading: true,
                error: action.payload.data.error
            };
            break;
        case ActionTypeList.TARGET_SETTING_AUTHENTICATION_TEST_RESULT_RESET:
        case ActionTypeList.TARGET_SETTING_AUTHENTICATION_TEST_SUBMITTED:
            response = {
                loading: false,
                isTargetAuthenticationTestCancellationSubmitted: false,
                error: ""
            };
            break;
        default:
            response = state;
            break;
    }

    //console.log(`final targetAuthenticationTestCancellationSubmissionPayload from action ${action.type}: ${JSON.stringify(response)}`)

    return response;
}

const initialResultState = {
    loading: false,
    targetAuthenticationTestResult: {},
    error: "",
};

export const targetAuthenticationTestResultPayload = (state = initialResultState, action) => {
    //console.log(`targetAuthenticationTestResultPayload initial state ${JSON.stringify(state)} action type ${action.type} payload ${JSON.stringify(action.payload)}`);

    let response = {};

    switch (action.type) {
        case ActionTypeList.TARGET_SETTING_AUTHENTICATION_TEST_RESULT_GOTTEN:
            response = {
                loading: false,
                targetAuthenticationTestResult: action.payload,
                error: ""
            };
            break;
        case ActionTypeList.TARGET_SETTING_GET_AUTHENTICATION_TEST_RESULT:
            response = {
                loading: true,
                error: action.payload.data.error
            };
            break;
        case ActionTypeList.TARGET_SETTING_AUTHENTICATION_TEST_RESULT_RESET:
        case ActionTypeList.TARGET_SETTING_AUTHENTICATION_TEST_SUBMITTED:
            response = {
                ...state,
                targetAuthenticationTestResult: {}
            }
            break;
        default:
            response = state;
            break;
    }

    //console.log(`final targetAuthenticationTestResultPayload from action ${action.type}: ${JSON.stringify(response)}`)

    return response;

}