import { AuthenticationService } from "../_services";
import { History } from './History';

export function handleResponse(response) {
    return response.text().then((text) => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if ([401, 403].indexOf(response.status) !== -1) {
                // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
                AuthenticationService.logout();
                window.location.reload(true);
            }

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}

export function handleJsonResponse(response) {
    //console.log('HandleJsonResponse, response: ' + JSON.stringify(response));

    const data = response.data;

    if (data.status !== 200) {

        const error = data.statusText;

        console.log('HandleJsonResponse, throw error: ' + JSON.stringify(error));

        throw error;
    }

    return data.data;
}

export function handleJsonResponseWithDefaultErrorMessage(response, errorMessage) {
    const data = response.data;

    if (data.status !== 200) {

        console.log('handleJsonResponseWithDefaultErrorMessage, throw error: ' + JSON.stringify(data.statusText));

        throw errorMessage;
    }

    return data.data;
}

export function performAuthenticationCheck(response) {

    let status, statusText;

    if (response === undefined || response === null) {
        return;
    }

    if (response.data !== undefined && response.data.status !== undefined) {
        status = response.data.status;
        statusText = response.data.statusText;
    } else {
        status = response.status;
        statusText = response.statusText;
    }

    if (status !== 200 && status !== 202) {
        if ([401, 403].indexOf(status) !== -1) {
            // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
            //console.log('AuthenticationService.logout() caused by 401/403');

            AuthenticationService.logout();

            //updateCurrentLoginUser(null);

            //console.log('history.push(authentication/login)');

            History.push("/authentication/login");
            //window.location.reload(true);
        }

        throw statusText;
    }
}
