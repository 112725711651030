import { ActionTypeList } from '../../constants'

const initialState = {
    loading: false,
    scanningsData: null,
    error: "",
};


const fetchScanningsPayload = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypeList.SCANNINGS_DATA_FETCHED:
            return {
                loading: false,
                scanningsData: action.payload,
                error: ""
            };
        case ActionTypeList.SCANNINGS_DATA_FETCH:
            return {
                loading: true,
                scanningsData: null,
                error: action.payload
            };
        default:
            return state;
    }
}

export default fetchScanningsPayload;
