import { ActionTypeList } from '../../constants'

const initialState = {
    loading: false,
    targetSettings: null,
    error: "",
};

export const targetSettingsPayload = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypeList.TARGET_SETTING_LIST_FETCHED:
            return {
                loading: false,
                targetSettings: action.payload,
                error: ""
            };
        case ActionTypeList.TARGET_SETTING_FETCH_LIST:
            return {
                loading: true,
                targetSettings: null,
                error: action.payload
            };
        default:
            return state;
    }
}

export const accountTargetSettingsPayload = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypeList.ACCOUNT_TARGET_SETTING_LIST_FETCHED:
            let result = [{ targetId: "-1", name: "No records", url: "", description: "", isActive: false }];

            if (action.payload?.length > 0) {
                result = action.payload;
            }

            return {
                loading: false,
                targetSettings: result,
                error: ""
            };
        case ActionTypeList.ACCOUNT_TARGET_SETTING_FETCH_LIST:
            return {
                loading: true,
                targetSettings: [],
                error: action.payload
            };
        default:
            return state;
    }
}

