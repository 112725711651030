import { ActionTypeList } from '../../constants'

const initialState = {
    loading: false,
    isSuccess: false,
    error: "",
};

const accountSettingUpdateStatusPayload = (state = initialState, action) => {
    //console.log(`targetSettingUpdateStatusPayload initial state ${JSON.stringify(state)} action type ${action.type} payload ${JSON.stringify(action.payload)}`);

    switch (action.type) {
        case ActionTypeList.ACCOUNT_SETTING_UPDATED:
            return {
                loading: false,
                isSuccess: true,
                error: ""
            };
        case ActionTypeList.ACCOUNT_SETTING_UPDATE:
            return {
                loading: true,
                isSuccess: false,
                error: action.payload.data.error
            };
        default:
            return state;
    }
}

export default accountSettingUpdateStatusPayload;