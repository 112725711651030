import { ActionTypeList } from '../../constants'

const initialState = {
    loading: false,
    notificationsData: { SelectTop: 10, Items: null },
    error: "",
};

const NotificationsPayload = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypeList.NOTIFICATIONS_DATA_FETCHED:
            return {
                loading: false,
                notificationsData: action.payload,
                error: ""
            };
        case ActionTypeList.NOTIFICATIONS_DATA_FETCH:
            return {
                loading: true,
                notificationsData: { SelectTop: 10, Items: null },
                error: action.payload
            };
        default:
            return state;
    }
}

export default NotificationsPayload;