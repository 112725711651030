import { DialogUtility } from '@syncfusion/ej2-popups';

export const errorBox = (message, okClickEvent) => {
    return messageBox('Error', message, 'error', okClickEvent);
}

export const warningBox = (message) => {
    return messageBox('Warning');
}

export const successBox = (message, okClickEvent) => {
    return messageBox('Success', message, 'success', okClickEvent);
}

const messageBox = (title, message, boxType, okClickEvent) => {
    if (boxType === 'success') {
        DialogUtility.confirm({
            closeOnEscape: true,
            content: message,
            title: title,
            showCloseIcon: false,
            position: { X: 'center', Y: 200 },
            cssClass: 'dialog-message-iwow',
            cancelButton: { text: 'Cancel', cssClass: 'e-hide' },
            close: okClickEvent
        });
    } else {
        DialogUtility.alert({
            closeOnEscape: true,
            content: message,
            title: title,
            showCloseIcon: true,
            position: { X: 'center', Y: 200 },
            cssClass: 'dialog-message-iwow',
            close: okClickEvent
        });
    }

    var dialogs = document.getElementsByClassName("e-dlg-container");

    if (dialogs) {
        for (var dialog of dialogs) {
            dialog.style.backgroundColor = 'transparent';

            switch (boxType) {
                case 'success':
                case 'error':
                case 'warning':
                    var contents = dialog.getElementsByClassName('e-dlg-content');

                    if (contents) {
                        for (var content of contents) {
                            if (content.classList.contains('e-cust-success-dlg')) {
                                continue;
                            }

                            content.classList.add('e-cust-success-dlg');
                        }
                    }
                    break;
                default:
                    break;
            }
        }
    }
}