import axios from 'axios'
import { ActionTypeEventMappingList } from '../redux/constants'
import { performAuthenticationCheck } from "../authentication/_helpers";
import { format, formatInTimeZone, utcToZonedTime } from 'date-fns-tz'
import { CURRENT_NOTIFICATIONS } from "../redux/constants";
import { NotificationsPage } from '../views/notifications/LocalNotifications';

const _defaultDateTimeFormat = 'dd/MMM/yyyy HH:mm (z)'

export function getNotifsReadStatus(notifications) {
    let unsavedNotifs = [];

    if (!notifications)
        return unsavedNotifs;

    for (var i = 0; i < notifications.length; i++) {
        if (notifications[i].unsaved) {
            delete notifications[i].unsaved;
            delete notifications[i].isOpen;

            unsavedNotifs.push(notifications[i]);
        }
    }

    return unsavedNotifs;
}

export function saveNotifsReadStatusToServer(accountId, localNotifications, pageNbr, dispatch, postNotifsReadStatusToServer) {
    let notifications = localNotifications.Get(pageNbr)?.Items;

    let unsavedNotifs = getNotifsReadStatus(notifications);

    if (unsavedNotifs.length > 0)
        dispatch(postNotifsReadStatusToServer(accountId, unsavedNotifs));
}

export function markAllAsReadAnsSaveToServer(accountId, localNotifications, pageNbr, dispatch, postNotifsReadStatusToServer) {
    let changed = false;

    let notifications = localNotifications.Get(pageNbr)?.Items;

    if (!notifications)
        return;

    for (var i = 0; i < notifications.length; i++) {
        if (!notifications[i].isRead) {
            notifications[i].unsaved = true;
            notifications[i].isRead = true;
            changed = true;
        }
    }

    if (changed) {
        localNotifications.Set(new NotificationsPage(notifications, pageNbr));

        saveNotifsReadStatusToServer(accountId, localNotifications, pageNbr, dispatch, postNotifsReadStatusToServer);        

        setTimeout(() => SaveToReduxStore(dispatch, CURRENT_NOTIFICATIONS, localNotifications), 200);
    }
}

export function getNotificationType(type) {
    const notificationTypes = [
        {
            "type": 0,
            "iconclass": "mdi mdi-magnify",
            "iconbg": "success",
            "title": "Issue Check Scan Succeed",
            "desc": "Please, check findings page for results.",
            "detail": "Issue Check Scan Succeed, please, check for results.in the <a href='/findings' onClick='(e) => e.stopPropagation(); return true;'>findings page</a>.",
        },
        {
            "type": 1,
            "iconclass": "mdi mdi-magnify",
            "iconbg": "danger",
            "title": "Issue Check Scan Error",
            "desc": "Please, contact your administarator.",
            "detail": "An unexpected Issue Check Scan Error occured in the server. Please, contact your system administarator.",
        },
        {
            "type": 2,
            "iconclass": "mdi mdi-magnify",
            "iconbg": "warning",
            "title": "Issue Check Scan Login Error",
            "desc": "Please, setup your logi in stting/authentication.",
            "detail": "Issue Check Scan failed because of login error. Please, setup your logi in the Authentication tab of the <a href='/settings' onClick='(e) => e.stopPropagation(); return true;'>settings page</a>.",
        },
        {
            "type": 3,
            "iconclass": "ti-search",
            "iconbg": "success",
            "title": "Scan Succeed",
            "desc": "Please, check scanning page for results.",
            "detail": "Scan Succeed, please, check scanning page for results.in the <a href='/scannings' onClick='(e) => e.stopPropagation(); return true;'>scannings page</a>.",
        },
        {
            "type": 4,
            "iconclass": "ti-search",
            "iconbg": "danger",
            "title": "Scan Error",
            "desc": "Please, contact your administarator.",
            "detail": "An unexpected scan error occured in the server. Please, contact your system administarator.",
        },
        {
            "type": 5,
            "iconclass": "ti-search",
            "iconbg": "warning",
            "title": "Scan Login Error",
            "desc": "Please, setup your logi in stting/authentication.",
            "detail": "Scan failed because of login error. Please, setup your logi in the Authentication tab of the <a href='/settings' onClick='(e) => e.stopPropagation(); return true;'>settings page</a>.",
        },
        {
            "type": 6,
            "iconclass": "ti-unlock",
            "iconbg": "warning",
            "title": "Suspicious login attempt",
            "desc": "Someone tried to login to your account multiple times.",
            "detail": "A suspicious login attempt was detected on your account. If it was your activity, please, ignore this notice. In case it was not you then someone tried to login to your account multiple times unsuccessfuly. Please consider change your password in the <a href='/users' onClick='(e) => e.stopPropagation(); return true;'>user list page</a>.",
        },
        {
            "type": 7,
            "iconclass": "ti-settings",
            "iconbg": "primary",
            "title": "Missing Assignees Settings",
            "desc": "Please, setup your assignees in account settings page.",
            "detail": "Your Assignees settings is missing some information. Please, provide the missing information in the <a href='/account-settings' onClick='(e) => e.stopPropagation(); return true;'>Account-settings page</a>.",
        },
        {
            "type": 8,
            "iconclass": "ti-settings",
            "iconbg": "primary",
            "title": "Missing Target Settings",
            "desc": "Please, setup your targets in account settings page.",
            "detail": "Your target settings is missing some information. Please, provide the missing information in the <a href='/account-settings' onClick='(e) => e.stopPropagation(); return true;'>Account settings page</a>.",
        },
        {
            "type": 9,
            "iconclass": "ti-settings",
            "iconbg": "primary",
            "title": "Missing Authentication Settings",
            "desc": "Please, setup your authentication in stting/authentication.",
            "detail": "Your authentication settings is missing some information. Please, provide the missing information in the <a href='/settings' onClick='(e) => e.stopPropagation(); return true;'>settings page</a>.",
        }
    ]
    let notificationType = notificationTypes.find(t => t.type == type);

    if (!notificationType)
        return {};

    return notificationType;
}
export function getWixHttpFctUrl(pathname) {
    return "https://cloud9security.io/_functions";
}
export function isPathExist(pathname) {
    let paths = [
        "/",
        "/targets",
        "/target-details",
        "/scannings",
        "/findings",
        "/users",
        "/settings",
        "/account-settings",
        "/billing",
        "/inbox",
        "/balance",
        "/profile",
        "/notifications",
        "/authentication/lockscreen",
        "/authentication/login",
        "/no-permission",
        "/not-found"
    ];

    if (paths.includes(pathname))
        return true;

    return false;
}
export function isUserAuthorized(permissions, pathname) {
    switch (pathname) {
        case "/": return permissions.Dashboard !== undefined;
        case "/targets":
        case "/target-details": return permissions.Targets !== undefined;
        case "/scannings": return permissions.Scannings !== undefined;
        case "/findings": return permissions.Findings !== undefined;
        case "/users": return permissions.UserList !== undefined;
        case "/settings": return permissions.ViewSettings !== undefined;
        case "/account-settings": return permissions.AccountSetting !== undefined;
        case "/billing": return permissions.AccountSetting !== undefined || permissions.ViewBilling;
        // case "/notifications": return permissions.AccountSetting !== undefined;

        default: return true;
    }
}
export * from "./messageBox";
export var ScanTypeList = { "None": -1, "SafeMode": 0, "Normale": 1, "OnePage": 2, }
export function disableButton(btnId, disable, onClick) {
    let btn = document.getElementById(btnId);
    if (!btn)
        return;

    if (disable) {
        btn.classList.add("disabled");
        btn.setAttribute("disabled", ""); // disabled = true;
        btn.onclick = {};
    }
    else {
        btn.classList.remove("disabled");
        btn.removeAttribute("disabled"); // disabled = false;
        btn.onclick = onClick;
    }
}
export function convertRemToPixels(rem) {
    return rem * parseFloat(getComputedStyle(document.documentElement).fontSize);
}
export function SaveToReduxStore(dispatch, type, payload) {
    dispatch({ type: type, payload: payload })
}
export function formatDate(value) {
    let date = new Date(value);
    const day = date.toLocaleString('default', { day: '2-digit' });
    const month = date.toLocaleString('default', { month: 'short' });
    const year = date.toLocaleString('default', { year: 'numeric' });
    return day + '/' + month + '/' + year;
}
export function setHtmlPageTitle(titlePart) {
    let title = `${titlePart} - Cloud9 security`;
    document.title = title;
    window.top.document.title = title;
}

export function getSortOrderList() {
    let result = new Map();

    result.set("None", -1);
    result.set("Ascending", 0);
    result.set("Descending", 1);

    return result;
}

export function getSeverity(severity) {
    //var severities = ['HIGH', 'MEDIUM', 'LOW'];
    //let i = parseInt(severity);
    //if (i === NaN)
    //    return null;

    //return severities[i]
    return severity;
}
export function getSeverityColor(severity, palettes) {
    if (!palettes)
        return null;

    let i = 0;
    switch (severity) {
        case "Low": i = 0; break;
        case "Medium": i = 1; break;
        case "High": i = 2; break;
        default: return null;
    }

    return palettes[i]
}
export function getReactDivElement(htmlStr) {
    return (
        <div dangerouslySetInnerHTML={{ __html: htmlStr }} />
    );
}

export const API_ROOT = () => {
    return getApiRoot();
}

export const getPtApiUrl = (accountId, action) => {

    const apiRoot = getApiRoot();

    //console.log(`getPtApiUrl() apiRoot ${apiRoot}`);

    return `${apiRoot}/accounts/${accountId}/pt/${action}`;
}


function getApiRoot() {
    let hostname = window && window.location && window.location.hostname;
    let backendHost = ``;

    //console.log(`API_ROOT() hostname ${hostname}`);

    if (hostname.includes("localhost")) {
        backendHost = "http://172.24.16.1:8787/apis";
        // backendHost = process.env.REACT_APP_LOCAL_UI_API_URL;
    } else {
        backendHost = process.env.REACT_APP_GATEWAY_API_URL;
    }

    //console.log(`API_ROOT() return ${backendHost}`);

    return backendHost;
}

export function getSalesApiRoot() {

    return process.env.REACT_APP_SALES_API_URL;
}


export const getAxios = (name) => {
    switch (name) {
        case '':
        case undefined:
            let axiosInst = axios.create({});
            return axiosInst;
        default:
            return null;
    }
}

export const createDivElementFromHTML = (htmlString) => {
    var div = document.createElement('div');
    div.innerHTML = '<div>' + htmlString.trim() + '</div>';

    return div;
}

const getWikiObjectFromHtml = (markup) => {
    //console.log(markup);
    var doc = (new DOMParser()).parseFromString(markup, "text/html");
    var mainContents = doc.querySelectorAll("#main-content");

    let wikiObject = [];
    wikiObject.push({
        header: createDivElementFromHTML("<div style='font-size: 1.1rem'>Information</div>"),
        content: mainContents[0]
    });
    wikiObject.push({
        header: createDivElementFromHTML("<div style='font-size: 1.1rem'>Background</div>"),
        content: mainContents[1]
    });
    wikiObject.push({
        header: createDivElementFromHTML("<div style='font-size: 1.1rem'>Classification</div>"),
        content: mainContents[2]
    });
    wikiObject.push({
        header: createDivElementFromHTML("<div style='font-size: 1.1rem'>Read more</div>"),
        content: mainContents[3]
    });

    return wikiObject;
}

export const getWikiObject = (axios, url, actionType) => async (dispatch) => {
    const config = {
        headers: {
            'accept': '*/*',
            'content-type': 'text/plain',
        }
    };

    const mappedActionType = getMappedActionType(actionType);

    await axios
        .get(url, config)
        .then((response) => {
            //console.log(`Response from ${url}: ${JSON.stringify(response)} action type ${actionType} action event ${ActionTypeEventMappingList[actionType]}`)
            if (response.status !== 200) {
                return dispatch(constructActionErrorResponse(mappedActionType, response.statusText))
            }

            let markup = response.data;
            if (!markup) {
                return dispatch(constructActionErrorResponse(mappedActionType, "No html page was returned from server."));
            }

            let wikiObject = getWikiObjectFromHtml(markup);
            return dispatch({
                type: mappedActionType,
                payload: wikiObject,
            });
        })
        .catch((error) => {
            return dispatch(constructActionErrorResponse(mappedActionType, error))
        });

    // let markup = await geRemotetWikiHtml(url);
    // if (!markup) {
    //     return dispatch(constructActionErrorResponse(ActionTypeEventMappingList[actionType], "No html page was returned from server."));
    // }

    // let wikiObject = getWikiObjectFromHtml(markup);
    // return dispatch({
    //     type: ActionTypeEventMappingList[actionType],
    //     payload: {wikiData: wikiObject},
    //     });
}

// const  geRemotetWikiHtml = async(url) => {
//     let html = null;
//     const iframe = document.createElement("iframe");
//     iframe.setAttribute("id", "wikiIframe");
//     document.body.appendChild(iframe);

//     let iframeLoadPromise = new Promise(function(resolve, reject) {
//         iframe.onload = () => {
//             resolve(iframe);
//         }
//         iframe.onerror = reject;
//         iframe.setAttribute("src", url);
//       });

//       await iframeLoadPromise.
//       then((iframe) => {
//         html = window.frames['wikiIframe'];//.document.body.innerHTML //iframe.innerHTML;
//         alert(html);
//         const x = iframe;//document.getElementById("wikiIframe")
//         const y = x.contentWindow || x.contentDocument
//         const html = y.document ? y.document.body.innerHTML : y.body.innerHTML
//         alert(html)
//       }).
//       catch((error)=>{
//           alert(error)
//         });

//     return html;
// }

//export const launchScanPost = (axios, url, payload, actionType) => async (dispatch) => {
//    const mappedActionType = getMappedActionType(actionType);

//    await axios
//        .post(url, payload, getConfig())
//        .then((response) => {
//            //console.log(`Response from ${url}: ${JSON.stringify(response)} action type ${actionType} action event ${ActionTypeEventMappingList[actionType]}`)
//            if (response.status !== 200) {
//                return dispatch(constructActionErrorResponse(mappedActionType, response.statusText))
//            }

//            let json = response.data;
//            if (!json) {
//                return dispatch(constructActionErrorResponse(mappedActionType, "No data was returned from server."));
//            }

//            return dispatch({
//                type: mappedActionType,
//                payload: json,
//            });
//        })
//        .catch((error) => {
//            return dispatch(constructActionErrorResponse(mappedActionType, error))
//        });
//}

//export const saveScheduledScansToDBPost = (axios, url, payload, actionType) => async (dispatch) => {
//    const mappedActionType = getMappedActionType(actionType);

//    await axios
//        .post(url, payload, getConfig())
//        .then((response) => {
//            //console.log(`Response from ${url}: ${JSON.stringify(response)} action type ${actionType} action event ${ActionTypeEventMappingList[actionType]}`)
//            if (response.status !== 200) {
//                return dispatch(constructActionErrorResponse(mappedActionType, response.statusText))
//            }

//            let json = response.data;
//            if (!json) {
//                return dispatch(constructActionErrorResponse(mappedActionType, "No data was returned from server."));
//            }

//            return dispatch({
//                type: mappedActionType,
//                payload: json,
//            });
//        })
//        .catch((error) => {
//            return dispatch(constructActionErrorResponse(mappedActionType, error))
//        });
//}

const getConfig = () => {
    const token = 'd6ac2fe46f34f522fbb55ee6c229c1367b2431be69ededa096c0909e1adef0d8';
    return {
        headers: { "Authorization": `Bearer ${token}` }
    };
}

export const constructActionResponse = (axios, url, actionType) => async (dispatch) => {
    const mappedActionType = getMappedActionType(actionType);

    await axios
        .get(url, getConfig)
        .then((response) => {
            //console.log(`Response from ${url}: ${JSON.stringify(response)} action type ${actionType} action event ${ActionTypeEventMappingList[actionType]}`)
            if (response.status !== 200) {
                return dispatch(constructActionErrorResponse(mappedActionType, response.statusText))
            } else if (response.data.status !== 200) {
                return dispatch(constructActionErrorResponse(mappedActionType, response.data.statusText))
            } else {
                return dispatch({
                    type: mappedActionType,
                    payload: response.data.data
                })
            }
        })
        .catch((error) => {
            return dispatch(constructActionErrorResponse(mappedActionType, error))
        });
}

export const constructActionResponseWithMock = (mockData, actionType) => async (dispatch) => {
    let response = {
        status: 200,
        data: mockData
    }

    console.log(`constructActionResponseWithMock dispatching. `);

    return dispatch(genericConstructActionResponse(response, actionType));
}

export const constructActionResponseWithGet = (axios, url, actionType) => async (dispatch) => {
    const config = {
        headers: {
            'accept': '*/*',
            'content-type': 'text/plain',
        }
    };

    console.log(`await axios.get(${url}, config) starts`);

    const mappedActionType = getMappedActionType(actionType);

    await axios
        .get(url, config)
        .then((response) => {
            //console.log(`constructActionResponseWithGet success response from ${url}: ${JSON.stringify(response)}`);

            performAuthenticationCheck(response);

            console.log(`constructActionResponseWithGet passed performAuthenticationCheck`);

            return dispatch(genericConstructActionResponse(response, actionType));
        })
        .catch((error) => {
            console.log(`error response from ${url}: ${JSON.stringify(error)}`);

            return dispatch(constructActionErrorResponse(mappedActionType, error))
        });
}

export const constructActionResponseWithPost = (axios, url, data, actionType, onSuccessCallBack) => async (dispatch) => {
    await axios
        .post(url, data)
        .then((response) => {
            //console.log(`constructActionResponseWithPost success response from ${url}: ${JSON.stringify(response)}`);

            performAuthenticationCheck(response);

            if (onSuccessCallBack !== undefined) {
                onSuccessCallBack(dispatch);
            }

            return dispatch(genericConstructActionResponse(response, actionType));
        })
        .catch((error) => {
            console.log(`constructActionResponseWithPost error response from ${url}: ${JSON.stringify(error)}`);
            return dispatch(constructActionErrorResponse(actionType, error));
        });
}

export const constructActionResponseWithPut = (axios, url, data, actionType) => async (dispatch) => {
    console.log(`constructActionResponseWithPut action ${actionType} data: ${JSON.stringify(data)} `)

    let mappedActionType = getMappedActionType(actionType);

    await axios
        .put(url, data)
        .then((response) => {
            performAuthenticationCheck(response);

            return dispatch(genericConstructActionResponse(response, actionType));
        })
        .catch((error) => {
            console.log(`constructActionResponseWithPut error response from ${url}: ${JSON.stringify(error)}`);

            return dispatch(constructActionErrorResponse(mappedActionType, error))
        });
}

export const constructActionResponseWithFormDataPut = (axios, url, formData, fileType, actionType) => async (dispatch) => {
    const mappedActionType = getMappedActionType(actionType);

    await axios
        .put(url, formData, {
            headers: {
                'Content-Type': fileType
            }
        })
        .then((response) => {
            return dispatch(genericConstructActionResponse(response, actionType));
        })
        .catch((error) => {
            console.log(`constructActionResponseWithPut error response from ${url}: ${JSON.stringify(error)}`);

            return dispatch(constructActionErrorResponse(mappedActionType, error))
        });
}

export const constructActionResponseWithDelete = (axios, url, data, actionType) => async (dispatch) => {
    await axios.delete(url, {
        data
    }).then((response) => {
        return dispatch(genericConstructActionResponse(response, actionType));
    }).catch((error) => {
        console.log(`constructActionResponseWithDelete error response from ${url}: ${JSON.stringify(error)}`);

        return dispatch(constructActionErrorResponse(actionType, error))
    });
}

export const constructActionResponseWithMockData = (mockData, actionType) => async (dispatch) => {
    //console.log(`constructActionResponseWithMockData  mock data: ${JSON.stringify(mockData)} `)
    const mappedActionType = getMappedActionType(actionType);

    return dispatch({
        type: mappedActionType,
        payload: mockData.data
    })
}

export const constructActionResponseWithInput = (input, actionType) => async (dispatch) => {
    //console.log(`constructActionResponseWithInput with input ${input} actionType ${actionType}`);
    const mappedActionType = getMappedActionType(actionType);

    return dispatch({
        type: mappedActionType,
        payload: input
    })
}

export const constructActionSuccessResponse = (actionType) => (dispatch) => {
    const mappedActionType = getMappedActionType(actionType);

    return dispatch({
        type: mappedActionType,
        payload: true
    })
}

export const formatUnixTimeToString = (milliseconds, timeZone, pattern) => {
    if (milliseconds === undefined ||
        milliseconds === null ||
        milliseconds === 0) {
        return '';
    }

    return formatInTimeZone(milliseconds, timeZone, pattern);
}

export const genericConstructActionResponse = (response, actionType) => {

    console.log(`genericConstructActionResponse response content length ${JSON.stringify(response)?.length} actionType ${actionType}`);

    const mappedActionType = getMappedActionType(actionType);

    if (response.status !== 200 && response.status !== 202) {
        return constructActionErrorResponse(actionType, response.statusText);
    } else if (response.data.status !== undefined && response.data.status !== 200 && response.data.status !== 202) {
        return constructActionErrorResponse(actionType, response.data.statusText);
    } else {
        //console.log(`genericConstructActionResponse response.data ${JSON.stringify(response.data)}`);
        if (response.data !== undefined && response.data.status !== undefined) {
            return {
                type: mappedActionType,
                payload: response.data.data
            };
        } else {
            return {
                type: mappedActionType,
                payload: response.data
            };
        }
    }
}

const getMappedActionType = (actionType) => {
    let mappedActionType = '';

    if (ActionTypeEventMappingList[actionType] === undefined) {
        mappedActionType = 'Done-' + actionType;
    } else {
        mappedActionType = ActionTypeEventMappingList[actionType];
    }

    return mappedActionType;
}

export const constructActionErrorResponse = (actionType, error) => {
    if (error !== undefined &&
        error.response !== undefined &&
        error.response.data !== undefined &&
        error.response.data.error !== undefined) {

        return {
            type: actionType,
            payload: {
                data: {
                    error: error.response.data.error,
                    hasError: true
                }
            }
        }

    } else {
        return {
            type: actionType,
            payload: {
                data: {
                    error: error,
                    hasError: true
                }
            }
        }
    }
}

export const getUserDefinedDateTimeString = (timeValue, timeZone) => {

    if (timeValue !== undefined && timeValue != null) {
        let date = null;

        if (typeof timeValue === 'string') {
            if (timeValue.includes("Z") === false &&
                timeValue.includes("+") === false &&
                timeValue.includes("-") === false) {
                timeValue += 'Z';
            }

            date = new Date(timeValue);
        } else {
            date = timeValue;
        }

        if ((date.getFullYear() === 1 &&
            date.getMonth() === 0 &&
            date.getDay() === 1) == false) {

            const result = format(utcToZonedTime(date, timeZone),
                _defaultDateTimeFormat, { timeZone });

            //console.log(`getUserDefinedDateTimeString(${timeValue},${timeZone}) = ${result}`);

            return result;
        }
    }

    return '';
}

export const getUserDefinedTimeZonedDate = (timeValue, timeZone) => {

    if (timeValue !== undefined && timeValue != null) {
        let date = null;

        if (typeof timeValue === 'string') {
            if (timeValue.includes("Z") === false) {
                timeValue += 'Z';
            }

            date = new Date(timeValue);
        } else {
            date = timeValue;
        }

        if ((date.getFullYear() === 1 &&
            date.getMonth() === 0 &&
            date.getDay() === 1) == false) {

            const result = utcToZonedTime(date, timeZone);

            console.log(`getUserDefinedTimeZonedDate(${timeValue},${timeZone}) = ${result}`);

            return result;
        }
    }

    return timeValue;
}

export const constructNewAction = (action, payload) => {
    return {
        type: action,
        payload
    };
}
