import { ActionTypeList } from '../constants'

const initialState = {
    loading: false,
    billingData: [],
    error: "",
};

const billingPayload = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypeList.BILLING_DATA_FETCHED:
            return {
                loading: false,
                billingData: action.payload.invoices,
                error: ""
            };
        case ActionTypeList.BILLING_DATA_FETCH:
            return {
                loading: true,
                billingData: [],
                error: action.payload
            };
        default:
            return state;
    }
}

export default billingPayload;