import { LocalNotifications } from "../../views/notifications/LocalNotifications";
import { dashboardData } from "../../_mock/dashboardData";
import {
    LOGO_BG,
    NAVBAR_BG,
    SIDEBAR_BG,
    THEME,
    DIRECTION,
    SIDEBAR_POSITION,
    HEADER_POSITION,
    LAYOUT,
    SIDEBAR_TYPE,
    CURRENT_TARGET,
    CURRENT_USER,
    PALETTES,
    TARGETS_DATA,
    CURRENT_ISSUE,
    CURRENT_AUTH_METHOD,
    CURRENT_NOTIFICATIONS
} from "../constants/";

const INIT_STATE = {
    activeDir: "ltr",
    activeThemeLayout: "vertical",
    activeTheme: "light",
    activeSidebarType: "full",
    activeLogoBg: "skin6",
    activeNavbarBg: "skin6",
    activeSidebarBg: "skin6",
    activeSidebarPos: "fixed",
    activeHeaderPos: "fixed",
    activeLayout: "full",
    currentTarget: null,
    palettes: ["#3399FF", "#F6B53F", "#E94649"],
    targetsData: dashboardData.targetsData,
    currentIssue: null,
    currentAuthMethod: -1,
    currentNotifications: new LocalNotifications()
};

const SettingsReducer = (state = INIT_STATE, action) => {

    switch (action.type) {
        case TARGETS_DATA:
            return {
                ...state,
                targetsData: action.payload,
            };
        case PALETTES:
            return {
                ...state,
                palettes: action.payload,
            };
        case CURRENT_TARGET:
            const result = {
                ...state,
                currentTarget: action.payload,
            };

            console.log(`SettingsReducer state ${JSON.stringify(state)} action ${JSON.stringify(action)} result ${JSON.stringify(result)}`);

            return result;
        case CURRENT_USER:
            return {
                ...state,
                currentUser: action.payload,
            };
        case CURRENT_ISSUE:
            return {
                ...state,
                currentIssue: action.payload,
            };
        case CURRENT_AUTH_METHOD:
            return {
                ...state,
                currentAuthMethod: action.payload,
            };
        case CURRENT_NOTIFICATIONS:
            return {
                ...state,
                currentNotifications: action.payload,
            };
        case LOGO_BG:
            return {
                ...state,
                activeLogoBg: action.payload,
            };
        case NAVBAR_BG:
            return {
                ...state,
                activeNavbarBg: action.payload,
            };
        case SIDEBAR_BG:
            return {
                ...state,
                activeSidebarBg: action.payload,
            };
        case THEME:
            return {
                ...state,
                activeTheme: action.payload,
            };
        case DIRECTION:
            return {
                ...state,
                activeDir: action.payload,
            };
        case SIDEBAR_POSITION:
            return {
                ...state,
                activeSidebarPos: action.payload,
            };
        case HEADER_POSITION:
            return {
                ...state,
                activeHeaderPos: action.payload,
            };
        case LAYOUT:
            return {
                ...state,
                activeLayout: action.payload,
            };
        case SIDEBAR_TYPE:
            return {
                ...state,
                activeSidebarType: action.payload,
            };
        default:
            return state;
    }
};

export default SettingsReducer;
