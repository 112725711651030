import { ActionTypeList } from '../../constants'

const initialState = {
    loading: false,
    isSuccess: false,
    successMessage: '',
    toShowMessage: false,
    error: "",
};

const targetDeactivationStatusPayload = (state = initialState, action) => {

    switch (action.type) {
        case ActionTypeList.TARGET_SETTING_DEACTIVATED:
            return {
                loading: false,
                isSuccess: true,
                successMessage: action.payload,
                toShowMessage: true,
                error: ""
            };
        case ActionTypeList.TARGET_SETTING_DEACTIVATE:
            return {
                loading: true,
                isSuccess: false,
                toShowMessage: true,
                error: action.payload.data.error
            };
        case ActionTypeList.TARGET_SETTING_DEACTIVATED_HIDE_MSG:
            return {
                loading: false,
                toShowMessage: false,
                isSuccess: false,
                error: "",
                successMessage: ``
            };
        default:
            return state;
    }
}

export default targetDeactivationStatusPayload;