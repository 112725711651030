import { ActionTypeList } from '../constants'

const initialState = {
    loading: false,
    settingsData: null,
    error: "",
};

const settingsDataPayload = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypeList.SETTINGS_DATA_FETCHED:
            return {
                loading: false,
                settingsData: action.payload,
                error: ""
            };
        case ActionTypeList.SETTINGS_DATA_FETCH:
            return {
                loading: true,
                settingsData: null,
                error: action.payload
            };
        default:
            return state;
    }
}

export default settingsDataPayload;