import { ActionTypeList } from '../../constants'

const initialState = false;

const editBillingSettingFlagPayload = (state = initialState, action) => {

    switch (action.type) {
        case ActionTypeList.ACCOUNT_SETTING_BILLING_EDIT_MODE_UPDATED:
            return action.payload;
        case ActionTypeList.ACCOUNT_SETTING_UPDATED:
            return false;
        default:
            return state;
    }
}

export default editBillingSettingFlagPayload;