import { ActionTypeList } from '../constants'

const initialState = {
    loading: false,
    currentLoginUser: undefined,
    isCurrentLoginUserUpdated: false,
    error: "",
    message: ``,
    isCurrentLoginUserEditMode: false,
    isCurrentLoginUserModified: false,
};

const currentLoginUserPayload = (state = initialState, action) => {
    //console.log(`editUserFlagPayload state ${JSON.stringify(state)} action ${JSON.stringify(action)}`);

    switch (action.type) {
        case ActionTypeList.SECURITY_LOGIN_INFO_GOTTEN:
        case ActionTypeList.AUTHENTICATION_UPDATE_CURRENT_LOGIN_USER:

            //console.log(`currentLoginUserPayload state ${JSON.stringify(state)} action ${JSON.stringify(action)}`);

            return {
                ...state,
                currentLoginUser: action.payload,
                error: ""
            };
        case ActionTypeList.LOGIN_USER_FIELD_UPDATED:
            const payload = action.payload;
            return {
                ...state,
                currentLoginUser: { ...state.currentLoginUser, [payload.fieldName]: payload.fieldValue },
                isCurrentLoginUserModified: true,
                error: ""
            };
        case ActionTypeList.LOGIN_USER_UPDATED:
            return { ...state,
                isCurrentLoginUserUpdated: true,
                message : action.payload,
                isCurrentLoginUserEditMode: false,
                isCurrentLoginUserModified: false,
            };
        case ActionTypeList.LOGIN_USER_UPDATE:
            return {
                ...state,
                isCurrentLoginUserUpdated: false,
                error: action.payload,
                message: ``
            };
        case ActionTypeList.LOGIN_USER_EDIT_MODE_UPDATED:
            return {
                ...state,
                isCurrentLoginUserEditMode: action.payload,
                isCurrentLoginUserUpdated: false,
                message: ''
            };
        default:
            return state;
    }
}

export default currentLoginUserPayload;