import { ActionTypeList } from '../../constants'

const initialState = {
    isManualLoginPageEditMode: false,
    isRecordLoginPageEditMode: false,
    isBasicAuthPageEditMode: false,
    isHttpNTLMPageEditMode: false,
    isFormAuthPageEditMode: false,
    isJWTAuthPageEditMode: false,
};

const editTargetAuthenticationFlagPayload = (state = initialState, action) => {
    //console.log(`editTargetAuthenticationFlagPayload state ${JSON.stringify(state)} action ${JSON.stringify(action)}`);

    switch (action.type) {
        case ActionTypeList.TARGET_SETTING_MANUAL_LOGIN_FLOW_AUTH_EDIT_MODE_UPDATED:
            return {
                ...state,
                isManualLoginPageEditMode: action.payload
            };
        case ActionTypeList.TARGET_SETTING_RECORD_LOGIN_FLOW_AUTH_EDIT_MODE_UPDATED:
            return {
                ...state,
                isRecordLoginPageEditMode: action.payload
            };
        case ActionTypeList.TARGET_SETTING_HTTP_NTLM_AUTH_EDIT_MODE_UPDATED:
            return {
                ...state,
                isHttpNTLMPageEditMode: action.payload
            };
        case ActionTypeList.TARGET_SETTING_BASIC_AUTH_EDIT_MODE_UPDATED:
            return {
                ...state,
                isBasicAuthPageEditMode: action.payload
            };
        case ActionTypeList.TARGET_SETTING_FORM_AUTH_EDIT_MODE_UPDATED:
            return {
                ...state,
                isFormAuthPageEditMode: action.payload
            };
        case ActionTypeList.TARGET_SETTING_JWT_AUTH_EDIT_MODE_UPDATED:
            return {
                ...state,
                isJWTAuthPageEditMode: action.payload
            };
        //case ActionTypeList.TARGET_SETTING_INSERTED:
        case ActionTypeList.TARGET_SETTING_UPDATED:
            return initialState;
        case ActionTypeList.TARGET_SETTING_MANUAL_LOGIN_FLOW_AUTH_UPDATE_EDIT_MODE:
        case ActionTypeList.TARGET_SETTING_BASIC_AUTH_UPDATE_EDIT_MODE:
        case ActionTypeList.TARGET_SETTING_HTTP_NTLM_AUTH_UPDATE_EDIT_MODE:
        case ActionTypeList.TARGET_SETTING_RECORD_LOGIN_FLOW_AUTH_UPDATE_EDIT_MODE:
        case ActionTypeList.TARGET_SETTING_FORM_AUTH_UPDATE_EDIT_MODE:
        case ActionTypeList.TARGET_SETTING_JWT_AUTH_UPDATE_EDIT_MODE:
            return state;
        default:
            return state;
    }
}

export default editTargetAuthenticationFlagPayload;