import { ActionTypeList } from '../constants'

const initialState = {
    loading: false,
    targetDetailsData: null,
    error: "",
};

const targetDetailsDataPayload = (state = initialState, action) => {
    //console.log(`targetDetailsDataPayload action ${JSON.stringify(action)}`)
    switch (action.type) {
        case ActionTypeList.TARGET_DETAILS_DATA_FETCHED:
            return {
                loading: false,
                targetDetailsData: action.payload,
                error: ""
            };
        case ActionTypeList.TARGET_DETAILS_DATA_FETCH:
            return {
                loading: true,
                targetDetailsData: null,
                error: action.payload
            };
        default:
            return state;
    }
}

export default targetDetailsDataPayload;