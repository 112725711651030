import { ActionTypeList } from '../../constants'

const initialState = {
    loading: false,
    error: "",
    userDetail: {
        userName: '',
        userId: '',
        userEmail: '',
        jobTitle: '',
        password: '',
        role: ''
    },
};

const currentUserDetailPayload = (state = initialState, action) => {
    //console.log(`currentUserDetailPayload state ${JSON.stringify(state)} action ${JSON.stringify(action)}`);

    switch (action.type) {
        case ActionTypeList.USER_SETTING_DETAIL_FETCHED:
            return {
                loading: false,
                userDetail: action.payload,
                error: ""
            };
        case ActionTypeList.USER_SETTING_FETCH_DETAIL:
            return {
                loading: true,
                userDetail: {},
                error: action.payload
            };
        case ActionTypeList.USER_SETTING_NEW_FLAG_TOGGLED:
            return {
                loading: false,
                userDetail: {},
                error: action.payload
            };
        case ActionTypeList.USER_SETTING_FIELD_UPDATED:
            const payload = action.payload;

            return {
                loading: false,
                userDetail: { ...state.userDetail, [payload.fieldName]: payload.fieldValue },
                error: ""
            };
        default:
            return state;
    }
}

export default currentUserDetailPayload;