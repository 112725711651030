import { ActionTypeList } from '../../constants'

const initialState = false;

export const targetDetailPageModePayload = (state = initialState, action) => {

    //console.log(`detailPageModePayload state ${JSON.stringify(state)} action ${JSON.stringify(action)}`);

    switch (action.type) {
        case ActionTypeList.TARGET_SETTING_DETAIL_PAGE_MODE_UPDATED:
            return action.payload;
        default:
            return state;
    }
}

export const accountTargetDetailPageModePayload = (state = initialState, action) => {

    //console.log(`detailPageModePayload state ${JSON.stringify(state)} action ${JSON.stringify(action)}`);

    switch (action.type) {
        case ActionTypeList.ACCOUNT_TARGET_SETTING_DETAIL_PAGE_MODE_UPDATED:
            return action.payload;
        default:
            return state;
    }
}