
export class NotificationsPage {
    Items;
    Index;

    constructor(notifs, pageNbr) {
        this.Items = notifs;
        this.Index =pageNbr;
    }
}

export class LocalNotifications {
    Pages = [];
    CurrentPageNbr = 1;
    TotalRecordsCount = 0;

    Add(page) {
        this.Set(page);
    }

    Push(page) {
        this.Pages.push(page);
    }

    Set(page) {
        let index = this.Pages.findIndex(p => p.Index == page.Index);

        if (index == -1) {
            this.Push(page);
            return;
        }            

        this.Pages[index] = page;
    }

    Get(pageIndex) {
        return this.Pages.find(p => p.Index == pageIndex);
    }

    Delete(pageIndex) {
        let index = this.Pages.findIndex(p => p.Index == pageIndex);

        if (index == -1)
            return;

        this.Pages = this.Pages.splice(index, 1);
    }
}