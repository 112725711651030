import { ActionTypeList } from '../constants'

const initialState = false;

export const targetDeactivationModeStatusPayload = (state = initialState, action) => {

    switch (action.type) {
        case ActionTypeList.TARGET_SETTING_NEW_FLAG_TOGGLED:
        case ActionTypeList.ACCOUNT_TARGET_SETTING_TRANSFER_MODE_INITIATED:
        case ActionTypeList.ACCOUNT_TARGET_SETTING_ACTIVATION_MODE_INITIATED:
            return false;
        case ActionTypeList.ACCOUNT_TARGET_SETTING_DEACTIVATION_MODE_INITIATED:
            return true;
        case ActionTypeList.ACCOUNT_TARGET_SETTING_INITIATE_TRANSFER_MODE:
            return state;
        default:
            return state;
    }
}

export const targetActivationModeStatusPayload = (state = initialState, action) => {

    switch (action.type) {
        case ActionTypeList.TARGET_SETTING_NEW_FLAG_TOGGLED:
        case ActionTypeList.ACCOUNT_TARGET_SETTING_TRANSFER_MODE_INITIATED:
        case ActionTypeList.ACCOUNT_TARGET_SETTING_DEACTIVATION_MODE_INITIATED:
            return false;
        case ActionTypeList.ACCOUNT_TARGET_SETTING_ACTIVATION_MODE_INITIATED:
            return true;
        case ActionTypeList.ACCOUNT_TARGET_SETTING_INITIATE_TRANSFER_MODE:
            return state;
        default:
            return state;
    }
}