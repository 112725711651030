import { ActionTypeList } from '../../constants'

const initialState = {
    loading: false,
    isSuccess: false,
    error: "",
};

const targetSettingDeleteStatusPayload = (state = initialState, action) => {
    //console.log(`targetUpdateStatusPayload state ${JSON.stringify(state)}`);

    switch (action.type) {
        case ActionTypeList.TARGET_SETTING_DELETED:
            return {
                loading: false,
                isSuccess: action.payload,
                error: ""
            };
        case ActionTypeList.TARGET_SETTING_DELETE:
            return {
                loading: true,
                isSuccess: false,
                error: action.payload
            };
        default:
            return state;
    }
}

export default targetSettingDeleteStatusPayload;