import { ActionTypeList } from '../../constants'

const initialState = false;

const editGeneralAccountSettingFlagPayload = (state = initialState, action) => {

    switch (action.type) {
        case ActionTypeList.ACCOUNT_SETTING_GENERAL_EDIT_MODE_UPDATED:
            return action.payload;
        case ActionTypeList.ACCOUNT_SETTING_UPDATED:
            return false;
        default:
            return state;
    }
}

export default editGeneralAccountSettingFlagPayload;