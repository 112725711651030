import { ActionTypeList } from '../../constants'

const initialState = {
    loading: false,
    users: [],
    setting: {},
    error: "",
};

const usersPayload = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypeList.USER_SETTING_LIST_FETCHED:
            return {
                loading: false,
                users: action.payload.data,
                setting: action.payload.setting,
                error: ""
            };
        case ActionTypeList.USER_SETTING_FETCH_LIST:
            return {
                loading: true,
                users: [],
                setting: {},
                error: action.payload
            };
        default:
            return state;
    }
}

export default usersPayload;