import { ActionTypeList } from '../../constants'

const initialState = {
    loading: false,
    statusUpdateData: null,
    error: "",
};

const updateStatusPayload = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypeList.STATUS_UPDATED:
            return {
                loading: false,
                statusUpdateData: action.payload,
                error: ""
            };
        case ActionTypeList.STATUS_UPDATE:
            return {
                loading: true,
                statusUpdateData: null,
                error: action.payload
            };
        default:
            return state;
    }
}

export default updateStatusPayload;