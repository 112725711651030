import { ActionTypeList } from '../constants'

const initialState = {
    loading: false,
    publicPlans: [],
    hasCalledApi: false,
    error: ""
};

export const publicPlansPayload = (state = initialState, action) => {
    //console.log(`editUserFlagPayload state ${JSON.stringify(state)} action ${JSON.stringify(action)}`);

    switch (action.type) {
        case ActionTypeList.PUBLIC_PLANS_FETCHED:
            console.log(`publicPlansPayload state ${JSON.stringify(state)} action ${JSON.stringify(action)}`);

            return {
                loading: false,
                publicPlans: action.payload ?? [],
                hasCalledApi: true,
                error: ""
            };
        case ActionTypeList.PUBLIC_PLANS_FETCH:
            return {
                loading: true,
                publicPlans: [],
                hasCalledApi: true,
                error: action.payload
            };
        default:
            return state;
    }
}

const initialCancellationState = {
    loading: false,
    success: null,
    showScreen: false,
    error: ""
};

export const accountSubscriptionCancellationStatusPayload = (state = initialCancellationState, action) => {
    //console.log(`editUserFlagPayload state ${JSON.stringify(state)} action ${JSON.stringify(action)}`);

    switch (action.type) {
        case ActionTypeList.ACCOUNT_SUBSCRIPTION_CANCELLED:
            return {
                ...state,
                loading: false,
                success: true,
                data: "Your subscription cancellation has been submitted.",
                error: ""
            };
        case ActionTypeList.ACCOUNT_SUBSCRIPTION_CANCEL:
            return {
                ...state,
                loading: true,
                success: false,
                error: action.payload.data.error
            };
        case ActionTypeList.CLOSE_MESSAGE_BOX:
        case ActionTypeList.ACCOUNT_SUBSCRIPTION_CLOSE_SCREEN:
            console.log(`accountSubscriptionCancellationStatusPayload action ${JSON.stringify(action)} state ${JSON.stringify(state)}`);

            return { ...initialCancellationState };
        case ActionTypeList.ACCOUNT_SUBSCRIPTION_SHOW_SCREEN:
            return {
                ...state,
                showScreen: true
            };
        default:
            return state;
    }
}
