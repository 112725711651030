import { ActionTypeList } from '../../constants'

const initialState = {
    loading: false,
    saveNotificationsReadStatusData: null,
    error: "",
};

const saveNotificationsReadStatusPayload = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypeList.NOTIFICATIONS_READ_STATUS_SAVED:
            return {
                loading: false,
                saveNotificationsReadStatusData: action.payload,
                error: ""
            };
        case ActionTypeList.NOTIFICATIONS_READ_STATUS_SAVE:
            return {
                loading: true,
                saveNotificationsReadStatusData: null,
                error: action.payload
            };
        default:
            return state;
    }
}

export default saveNotificationsReadStatusPayload;