import { ActionTypeList } from '../constants'

const initialState = {
    loading: false,
    dashboardData: null,
    error: "",
};

const dashboardDataPayload = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypeList.DASHBOARD_DATA_FETCHED:
            return {
                loading: false,
                dashboardData: action.payload,
                error: ""
            };
        case ActionTypeList.DASHBOARD_DATA_FETCH:
            return {
                loading: true,
                dashboardData: null,
                error: action.payload
            };
        default:
            return state;
    }
}

export default dashboardDataPayload;