import { ActionTypeList } from '../../constants/index'

const initialState = false;

const editTargetSettingFlagPayload = (state = initialState, action) => {
    //console.log(`editTargetFlagPayload state ${JSON.stringify(state)} action ${JSON.stringify(action)}`);

    switch (action.type) {
        case ActionTypeList.TARGET_SETTING_EDIT_MODE_UPDATED:
            return action.payload;
        case ActionTypeList.TARGET_SETTING_UPDATED:
            return false;
        case ActionTypeList.TARGET_SETTING_UPDATE_EDIT_MODE:
            return state;
        default:
            return state;
    }
}

export default editTargetSettingFlagPayload;