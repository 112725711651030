"use strict";

Object.defineProperty(exports, "__esModule", { value: true })

exports.dashboardData = JSON.parse(`
{
    "data_Vulnerabilities": [
        {
            "x": "High",
            "y": 10,
            "r": "70%"
        },
        {
            "x": "Medium",
            "y": 17,
            "r": "80%"
        },
        {
            "x": "Low",
            "y": 33,
            "r": "90%"
        }
    ],
    "data_AverageTimeToFix": [
        [
            {
                "x": "High",
                "y": 10
            }
        ],
        [
            {
                "x": "Medium",
                "y": 17
            }
        ],
        [
            {
                "x": "Low",
                "y": 33
            }
        ]
    ],
    "data_IssuesPendingFix": [
        {
            "Id": 10248,
            "Name": "Backup file",
            "Severity": "2",
            "Duration": 7,
            "Name": "Chuah Eng Eng"
        },
        {
            "Id": 10249,
            "Name": "Input returned in response (reflected)",
            "Severity": "1",
            "Duration": 43,
            "Name": "Tan Fhih Ling"
        },
        {
            "Id": 10250,
            "Name": "Cross-domain script include",
            "Severity": "0",
            "Duration": 10,
            "Name": "Tan Fhih Ling"
        },
        {
            "Id": 10251,
            "Name": "Cross-origin resource sharing",
            "Severity": "1",
            "Duration": 43,
            "Name": "Vijay"
        },
        {
            "Id": 10252,
            "Name": "Directory listing",
            "Severity": "1",
            "Duration": 40,
            "Name": "Sharan"
        }
    ],
    "data_TargetsAtRisk": [
        {
            "Id": 10248,
            "Target": "TEST iWoWHRMS Admin",
            "ScanDate": "02/Feb/2022",
            "High": 36,
            "Medium": 22,
            "Low": 30
        },
        {
            "Id": 10249,
            "Target": "TEST iWoWHRMS Employee",
            "ScanDate": "05/Feb/2022",
            "High": 12,
            "Medium": 5,
            "Low": 8
        },
        {
            "Id": 10250,
            "Target": "PROD iWoWHRMS Admin",
            "ScanDate": "10/Feb/2022",
            "High": 3,
            "Medium": 2,
            "Low": 33
        },
        {
            "Id": 10251,
            "Target": "TEST iWoWHRMS Api",
            "ScanDate": "07/Feb/2022",
            "High": 37,
            "Medium": 23,
            "Low": 13
        },
        {
            "Id": 10252,
            "Target": "TEST iWoWHRMS Admin",
            "ScanDate": "03/Feb/2022",
            "High": 7,
            "Medium": 17,
            "Low": 38
        }
    ],
    "data_RecentSignificantVulnerabilities": [
        {
            "Id": 10248,
            "Name": "Serialized object in HTTP message",
            "Severity": "1",
            "RiskScore": 47
        },
        {
            "Id": 10249,
            "Name": "Robots.txt file",
            "Severity": "1",
            "RiskScore": 48
        },
        {
            "Id": 10250,
            "Name": "Flash cross-domain policy",
            "Severity": "0",
            "RiskScore": 6
        },
        {
            "Id": 10251,
            "Name": "SQL injection",
            "Severity": "1",
            "RiskScore": 29
        },
        {
            "Id": 10252,
            "Name": "Backup file",
            "Severity": "0",
            "RiskScore": 14
        }
    ],
    "data_Scans": {
        "Recent": [
            {
                "Id": 10248,
                "Target": "TEST iWoWHRMS Employee",
                "ScanDate": "05/Feb/2022",
                "Success": 0,
                "ReportUrl": "/static/media/iwowhrms_admin-20190213.7b4905c8.pdf",
                "High": null,
                "Medium": null,
                "Low": null
            },
            {
                "Id": 10249,
                "Target": "PROD iWoWHRMS Admin",
                "ScanDate": "06/Feb/2022",
                "Success": 1,
                "ReportUrl": "/static/media/iwowhrms_admin-20190213.7b4905c8.pdf",
                "High": 36,
                "Medium": 12,
                "Low": 5
            },
            {
                "Id": 10250,
                "Target": "PROD iWoWHRMS Employee",
                "ScanDate": "01/Feb/2022",
                "Success": 2,
                "ReportUrl": "/static/media/iwowhrms_admin-20190213.7b4905c8.pdf",
                "High": 14,
                "Medium": 29,
                "Low": 37
            },
            {
                "Id": 10251,
                "Target": "TEST iWoWHRMS Api",
                "ScanDate": "10/Feb/2022",
                "Success": 1,
                "ReportUrl": "/static/media/iwowhrms_admin-20190213.7b4905c8.pdf",
                "High": 33,
                "Medium": 8,
                "Low": 14
            },
            {
                "Id": 10252,
                "Target": "PROD iWoWHRMS Employee",
                "ScanDate": "02/Feb/2022",
                "Success": 0,
                "ReportUrl": "/static/media/iwowhrms_admin-20190213.7b4905c8.pdf",
                "High": null,
                "Medium": null,
                "Low": null
            },
            {
                "Id": 10253,
                "Target": "TEST iWoWHRMS Employee",
                "ScanDate": "09/Feb/2020",
                "Success": 1,
                "ReportUrl": "/static/media/iwowhrms_admin-20190213.7b4905c8.pdf",
                "High": 32,
                "Medium": 25,
                "Low": 0
            },
            {
                "Id": 10254,
                "Target": "PROD iWoWHRMS Admin",
                "ScanDate": "10/Feb/2022",
                "Success": 0,
                "ReportUrl": "/static/media/iwowhrms_admin-20190213.7b4905c8.pdf",
                "High": null,
                "Medium": null,
                "Low": null
            }
        ],
        "Ongoing": [
            {
                "Id": 10248,
                "Target": "PROD iWoWHRMS Employee",
                "ExpectedDuration": 2,
                "ScannedPages": "115/308",
                "ScanDate": "03/Feb/2022 4:12"
            },
            {
                "Id": 10249,
                "Target": "PROD iWoWHRMS Employee",
                "ExpectedDuration": 0,
                "ScannedPages": "158/220",
                "ScanDate": "07/Feb/2022 16:45"
            },
            {
                "Id": 10250,
                "Target": "TEST iWoWHRMS Api",
                "ExpectedDuration": 0,
                "ScannedPages": "96/350",
                "ScanDate": "08/Feb/2022 19:25"
            },
            {
                "Id": 10251,
                "Target": "TEST iWoWHRMS Admin",
                "ExpectedDuration": 1,
                "ScannedPages": "225/276",
                "ScanDate": "07/Feb/2022 16:45"
            },
            {
                "Id": 10252,
                "Target": "TEST iWoWHRMS Api",
                "ExpectedDuration": 3,
                "ScannedPages": "157/205",
                "ScanDate": "04/Feb/2022 6:15"
            },
            {
                "Id": 10253,
                "Target": "TEST iWoWHRMS Api",
                "ExpectedDuration": 2,
                "ScannedPages": "224/318",
                "ScanDate": "10/Feb/2022 23:10"
            },
            {
                "Id": 10254,
                "Target": "PROD iWoWHRMS Api",
                "ExpectedDuration": 0,
                "ScannedPages": "344/372",
                "ScanDate": "09/Feb/2020 22:45"
            }
        ],
        "Scheduled": [
            {
                "Id": 10248,
                "Target": "TEST iWoWHRMS Admin",
                "ScanDate": "06/Feb/2022 13:22"
            },
            {
                "Id": 10249,
                "Target": "PROD iWoWHRMS Api",
                "ScanDate": "04/Feb/2022 6:15"
            },
            {
                "Id": 10250,
                "Target": "TEST iWoWHRMS Employee",
                "ScanDate": "09/Feb/2020 22:45"
            },
            {
                "Id": 10251,
                "Target": "PROD iWoWHRMS Api",
                "ScanDate": "05/Feb/2022 11:55"
            },
            {
                "Id": 10252,
                "Target": "PROD iWoWHRMS Employee",
                "ScanDate": "01/Feb/2022 00:25"
            },
            {
                "Id": 10253,
                "Target": "TEST iWoWHRMS Admin",
                "ScanDate": "04/Feb/2022 6:15"
            },
            {
                "Id": 10254,
                "Target": "TEST iWoWHRMS Api",
                "ScanDate": "07/Feb/2022 16:45"
            }
        ]
    },
    "data_VulnerabilitiesInTime": [
        {
            "name": "High",
            "data": [
                {
                    "month": "Jan",
                    "y": 5
                },
                {
                    "month": "Feb",
                    "y": 4
                },
                {
                    "month": "Mar",
                    "y": 0
                },
                {
                    "month": "Apr",
                    "y": 5
                },
                {
                    "month": "May",
                    "y": 1
                },
                {
                    "month": "Jun",
                    "y": 2
                },
                {
                    "month": "Jul",
                    "y": 8
                },
                {
                    "month": "Aug",
                    "y": 4
                },
                {
                    "month": "Sep",
                    "y": 6
                },
                {
                    "month": "Oct",
                    "y": 7
                },
                {
                    "month": "Nov",
                    "y": 5
                },
                {
                    "month": "Dec",
                    "y": 8
                }
            ]
        },
        {
            "name": "Medium",
            "data": [
                {
                    "month": "Jan",
                    "y": 12
                },
                {
                    "month": "Feb",
                    "y": 15
                },
                {
                    "month": "Mar",
                    "y": 11
                },
                {
                    "month": "Apr",
                    "y": 12
                },
                {
                    "month": "May",
                    "y": 14
                },
                {
                    "month": "Jun",
                    "y": 15
                },
                {
                    "month": "Jul",
                    "y": 15
                },
                {
                    "month": "Aug",
                    "y": 10
                },
                {
                    "month": "Sep",
                    "y": 10
                },
                {
                    "month": "Oct",
                    "y": 13
                },
                {
                    "month": "Nov",
                    "y": 13
                },
                {
                    "month": "Dec",
                    "y": 11
                }
            ]
        },
        {
            "name": "Low",
            "data": [
                {
                    "month": "Jan",
                    "y": 22
                },
                {
                    "month": "Feb",
                    "y": 39
                },
                {
                    "month": "Mar",
                    "y": 38
                },
                {
                    "month": "Apr",
                    "y": 21
                },
                {
                    "month": "May",
                    "y": 23
                },
                {
                    "month": "Jun",
                    "y": 24
                },
                {
                    "month": "Jul",
                    "y": 23
                },
                {
                    "month": "Aug",
                    "y": 21
                },
                {
                    "month": "Sep",
                    "y": 28
                },
                {
                    "month": "Oct",
                    "y": 28
                },
                {
                    "month": "Nov",
                    "y": 19
                },
                {
                    "month": "Dec",
                    "y": 37
                }
            ]
        }
    ],
    "data_NewSolvedVulnerabilitiesInTime": [
        {
            "name": "New",
            "data": [
                {
                    "month": "Jan",
                    "y": 12
                },
                {
                    "month": "Feb",
                    "y": 8
                },
                {
                    "month": "Mar",
                    "y": 25
                },
                {
                    "month": "Apr",
                    "y": 31
                },
                {
                    "month": "May",
                    "y": 11
                },
                {
                    "month": "Jun",
                    "y": 29
                },
                {
                    "month": "Jul",
                    "y": 8
                },
                {
                    "month": "Aug",
                    "y": 13
                },
                {
                    "month": "Sep",
                    "y": 13
                },
                {
                    "month": "Oct",
                    "y": 23
                },
                {
                    "month": "Nov",
                    "y": 23
                },
                {
                    "month": "Dec",
                    "y": 29
                }
            ]
        },
        {
            "name": "Reoccured",
            "data": [
                {
                    "month": "Jan",
                    "y": 4
                },
                {
                    "month": "Feb",
                    "y": 3
                },
                {
                    "month": "Mar",
                    "y": 13
                },
                {
                    "month": "Apr",
                    "y": 38
                },
                {
                    "month": "May",
                    "y": 36
                },
                {
                    "month": "Jun",
                    "y": 28
                },
                {
                    "month": "Jul",
                    "y": 37
                },
                {
                    "month": "Aug",
                    "y": 12
                },
                {
                    "month": "Sep",
                    "y": 7
                },
                {
                    "month": "Oct",
                    "y": 0
                },
                {
                    "month": "Nov",
                    "y": 3
                },
                {
                    "month": "Dec",
                    "y": 33
                }
            ]
        },
        {
            "name": "Fixed",
            "data": [
                {
                    "month": "Jan",
                    "y": 35
                },
                {
                    "month": "Feb",
                    "y": 0
                },
                {
                    "month": "Mar",
                    "y": 22
                },
                {
                    "month": "Apr",
                    "y": 32
                },
                {
                    "month": "May",
                    "y": 36
                },
                {
                    "month": "Jun",
                    "y": 18
                },
                {
                    "month": "Jul",
                    "y": 6
                },
                {
                    "month": "Aug",
                    "y": 39
                },
                {
                    "month": "Sep",
                    "y": 1
                },
                {
                    "month": "Oct",
                    "y": 22
                },
                {
                    "month": "Nov",
                    "y": 15
                },
                {
                    "month": "Dec",
                    "y": 27
                }
            ]
        }
    ],
    "targetsData": [
        {
            "Url": "https://pentest-production.iwowsoft.com",
            "Name": "PROD iWoWHRMS Admin",
            "Description": "",
            "Critical": 5,
            "Moderate": 7,
            "Low": 12,
            "Total": 24,
            "DateLastScan": "2/1/2022"
        },
        {
            "Url": "https://pentest.iwowsoft.com",
            "Name": "TEST iWoWHRMS Admin",
            "Description": "",
            "Critical": 4,
            "Moderate": 2,
            "Low": 8,
            "Total": 14,
            "DateLastScan": "4/1/2022"
        },
        {
            "Url": "https://pentest-production.iwowsoft.com",
            "Name": "PROD iWoWHRMS Employee",
            "Description": "",
            "Critical": 5,
            "Moderate": 7,
            "Low": 12,
            "Total": 24,
            "DateLastScan": "3/1/2022"
        },
        {
            "Url": "https://pentest.iwowsoft.com",
            "Name": "TEST iWoWHRMS Employee",
            "Description": "",
            "Critical": 10,
            "Moderate": 5,
            "Low": 15,
            "Total": 30,
            "DateLastScan": "1/1/2022"
        },
        {
            "Url": "https://pentest.iwowsoft.com",
            "Name": "TEST iWoWHRMS Admin",
            "Description": "",
            "Critical": 12,
            "Moderate": 10,
            "Low": 5,
            "Total": 27,
            "DateLastScan": "4/1/2022"
        },
        {
            "Url": "https://pentest-production.iwowsoft.com",
            "Name": "PROD iWoWHRMS Employee",
            "Description": "",
            "Critical": 5,
            "Moderate": 3,
            "Low": 10,
            "Total": 18,
            "DateLastScan": "3/1/2022"
        }
    ]
}
`
);