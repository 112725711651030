import { ActionTypeList } from '../../constants'

const initialState = false;

const newUserFlagPayload = (state = initialState, action) => {

    switch (action.type) {
        case ActionTypeList.USER_SETTING_NEW_FLAG_TOGGLED:
        case ActionTypeList.USER_SETTING_INSERTED:
            return true;
        case ActionTypeList.USER_SETTING_TOGGLE_NEW_FLAG:
            return state;
        case ActionTypeList.USER_SETTING_CURRENT_USER_ID_UPDATED:
            return false;
        default:
            return state;
    }
}

export default newUserFlagPayload;