import { ActionTypeList } from '../../constants'

const initialState = false;

const detailPageModePayload = (state = initialState, action) => {

    //console.log(`detailPageModePayload state ${JSON.stringify(state)} action ${JSON.stringify(action)}`);

    switch (action.type) {
        case ActionTypeList.USER_SETTING_DETAIL_PAGE_MODE_UPDATED:
            return action.payload;
        default:
            return state;
    }
}

export default detailPageModePayload;